import {Params} from '@angular/router';

import {isNumberValue} from '@helpers/is-number';

import {BoatShortInfoCharterDto, BoatShortInfoCharterListDto} from '../boat';
import {BankAccountStatementsItem, CreditCardStatementsItem} from '../credit-cards';
import {CurrencyDto, PaymentMethodDto, UserRoleType} from '../directories';
import {ConditionalStatus} from '../general';
import {UploadedFile} from '../uploaded-file';

export type ExpenseFormMode = 'create' | 'edit' | 'preview';

export type ExpenseSplitType = 'simple' | 'split' | 'creditCardTopUp' | 'bankAccountIncomeOutcome';

export enum CrewStatuses {
  HIDDEN = 0,
  STATUS_ON_BOAT = 1,
  STATUS_PAST = 2,
  STATUS_VACATION = 3,
  LOOKING_FOR_JOB = 9,
  BUSY_ON_ANOTHER_BOAT
}

export enum ExpenseStateTime  {
  Old = 1,
  New
}

export type ExpenseCrewDto = Readonly<{
  userId: number;
  name: string;
  image: string;
  departmentRelated: number;
  userRole: UserRoleType;
  positionId: number | null;
  selected?: boolean;
  crewId?: number | null;
  creatorBoat?: boolean;
  isDefaultForAssignedTask?: boolean;
}> & {
  // TODO: replace to readonly properties
  status: CrewStatuses;
};

export class FinancialDataCrewDirectory {
  constructor(
    public id: number,
    public name: string,
  ) {
  }
}

export type ExpenseDepartmentDto = Readonly<{
  id: number | string;
  name: string;
  status: boolean;
}>;

export enum ExpenseCategoryType {
  Common = 1,
  CharterApa,
  Charter
}

export type ExpenseCategoryDto = Readonly<{
  id: number;
  name: string;
  status: boolean;
  categoryType: ExpenseCategoryType;
  subcategories?: SubCategoryDto[];
}>;

export type AdditionalFieldsDto = Readonly<{
  id: number;
  typeField: number;
  name: string;
  value: string;
}>;

export type SubCategoryDto = Readonly<{
  id: number;
  name: string;
  additionalFields: AdditionalFieldsDto[];
  status: boolean;
}>;

export class SplitDto {
  vatPercent?: number | null;

  constructor(
    public readonly amountOriginal: number | null,
    public readonly additionalFields: AdditionalFieldsDto[] = [],
    public readonly amountIncludesVat: number | null = null,
    public readonly categoryId: number | null = null,
    public readonly creditCardId: number | null = null,
    public readonly creditCardExchangeRate: number | null = null,
    public readonly currencyOriginId: number | null = null,
    public departmentTwoId: number | 'guest' | null = null,
    public readonly description: string = '',
    public readonly disableEdit: number | null = null,
    public readonly exchangeRate: number | null = null,
    public readonly expenseRelatedType: boolean = false,
    public readonly optionGuest: 1 | 0 = 0,
    public paymentMethodId: number | null = null,
    public readonly subCategoryId: number | null = null,
    public vatStatus: ConditionalStatus | null = null,
    public stateTime: ExpenseStateTime = ExpenseStateTime.New,
    public bankAccountStateTime = ExpenseStateTime.New,
    public readonly id?: number,
    public readonly conversationAmount?: number | null,
    public readonly invoiceNumberNotRelevant: boolean = false,
    public readonly invoiceNumber?: string,
    public readonly supplierNameNotRelevant: boolean = false,
    public readonly supplierName?: string,
    public readonly bankAccountId: number | null = null,
  ) {
  }
}

export class UpdateSplitCurrency {
  constructor(
    public readonly amountOriginal: number,
    public readonly currencyOriginId: number | null = null,
    public readonly exchangeRate: number | null = null,
    public readonly stateTime: ExpenseStateTime = ExpenseStateTime.New,
  ) {
  }

}

export const crewOnBoat = (
  crewList: ExpenseCrewDto[],
): ExpenseCrewDto[] => crewList.filter(crew => crew.status === CrewStatuses.STATUS_ON_BOAT);

export const filterCrewListByRoles = (crewList: ExpenseCrewDto[], onBoat: boolean, excludeRoles: UserRoleType[])
: ExpenseCrewDto[] => {
  const list = onBoat ? crewOnBoat(crewList) : crewList;
  return list.reduce((acc: ExpenseCrewDto[], curr) => {
    if (!excludeRoles.some(er => curr.userRole === er)) {
      acc.push(curr);
      return acc;
    }
    if (!onBoat) {
      curr.status = CrewStatuses.HIDDEN;
      acc.push(curr);
    }
    return acc;
  }, []);
};

export const selfCrewList = (crewList: ExpenseCrewDto[], isCreate: boolean, self: ExpenseCrewDto): ExpenseCrewDto[] => {
  if (isCreate) {
    return [self];
  }
  return crewList.map(crew => {
    if (crew.crewId !== self.crewId) {
      crew.status = CrewStatuses.HIDDEN;
    }
    return crew;
  });
};

export type ExpenseDirectoriesBaseParams = {
  currency?: CurrencyDto[];
  categories?: ExpenseCategoryDto[];
  subCategories?: SubCategoryDto[];
  crew?: ExpenseCrewDto[];
  departments?: ExpenseDepartmentDto[];
  creditCards?: CreditCardStatementsItem[];
  userId: number;
};

export class ExpenseDirectoriesBase {

  readonly currency: CurrencyDto[];
  readonly categories: ExpenseCategoryDto[];
  readonly subCategories: SubCategoryDto[];
  readonly crew: ExpenseCrewDto[];
  readonly departments: ExpenseDepartmentDto[];
  readonly creditCards: CreditCardStatementsItem[];
  readonly userId: number;

  private get crewOnBoat(): ExpenseCrewDto[] {
    return crewOnBoat(this.crew);
  }

  private get userCrew(): ExpenseCrewDto | null {
    return this.crewOnBoat.find(crew => crew.userId === this.userId) || null;
  }

  private get userRole(): UserRoleType | null {
    const user = this.userCrew;
    return user && user.userRole || null;
  }

  constructor(params: ExpenseDirectoriesBaseParams) {
    this.currency = params.currency || [];
    this.categories = params.categories || [];
    this.subCategories = params.subCategories || [];
    this.crew = params.crew || [];
    this.departments = params.departments || [];
    this.creditCards = params.creditCards || [];
    this.userId = params.userId;
  }

  crewList(mode: ExpenseFormMode): ExpenseCrewDto[] {
    const userRole = this.userRole;
    const isCreate = mode === 'create';
    const user = this.userCrew;
    const crewList = this.crew;
    if (!userRole || !user) {
      return [];
    }
    if (userRole === 'role_boat_owner' || user.creatorBoat) {
      return isCreate ? crewOnBoat(crewList) : crewList;
    }
    if (userRole === 'role_boat_manager') {
      return filterCrewListByRoles(crewList, isCreate, ['role_boat_owner']);
    }
    if (userRole === 'role_boat_captain') {
      return filterCrewListByRoles(crewList, isCreate, ['role_boat_owner', 'role_boat_manager']);
    }
    if (userRole === 'role_crew') {
      return selfCrewList(crewList, isCreate, user);
    }

    return [];
  }

  departmentsList(mode: ExpenseFormMode): ExpenseDepartmentDto[] {
    if (mode === 'create') {
      return this.departments.filter(item => item.status);
    }
    return this.departments;
  }

  currencyList(mode: ExpenseFormMode): CurrencyDto[] {
    if (mode === 'create') {
      return this.currency.filter(item => item.status);
    }
    return this.currency;
  }

  categoryList(mode: ExpenseFormMode): ExpenseCategoryDto[] {
    if (mode === 'create') {
      return this.categories.filter(item => item.status);
    }
    return this.categories;
  }

  subCategoriesByCategory(categoryId: number, mode: ExpenseFormMode): SubCategoryDto[] {

    const category = this.categories.find(item => item.id === categoryId);
    if (mode === 'create') {
      const subcategories = category && category.subcategories || [];
      return subcategories.filter(item => item.status);
    }
    return category && category.subcategories || [];
  }

  selectedCrewId(mode: ExpenseFormMode): number | null {
    const selected = this.crewList(mode).find(crew => crew.selected);
    return selected && selected.crewId || null;
  }

  creditCardsList(mode: ExpenseFormMode): CreditCardStatementsItem[] {
    if (mode === 'create') {
      return this.creditCards.filter(item => item.status);
    }
    return this.creditCards;
  }

}

type ExpenseDirectoriesParams = ExpenseDirectoriesBaseParams & {
  paymentMethods?: PaymentMethodDto[];
  charters?: BoatShortInfoCharterListDto;
  bankAccounts?: BankAccountStatementsItem[];
};

export class ExpenseDirectories extends ExpenseDirectoriesBase {

  paymentMethods: PaymentMethodDto[];
  charters: BoatShortInfoCharterListDto;
  bankAccounts: BankAccountStatementsItem[];

  constructor(params: ExpenseDirectoriesParams) {
    super(params);
    this.paymentMethods = params.paymentMethods || [];
    this.charters = params.charters || {running: [], past: [], upcoming: []};
    this.bankAccounts = params.bankAccounts || [];
  }

  paymentMethodById(id: number): PaymentMethodDto | null {
    return this.paymentMethods.find(method => method.id === id) || null;
  }

  crewById(id: number, mode: ExpenseFormMode): ExpenseCrewDto | null {
    return this.crewList(mode).find(crew => crew.crewId === id) || null;
  }

  excludeDepartment(id: number, mode: ExpenseFormMode): ExpenseDepartmentDto[] {
    return this.departmentsList(mode).filter(department => department.id !== id);
  }

  paymentMethodsList(type: ExpenseSplitType, includeOutstandingPayment = false): PaymentMethodDto[] {
    if (type === 'creditCardTopUp') {
      return this.paymentMethods.filter(item => item.creditCardTopUp);
    }
    if (type === 'simple' && includeOutstandingPayment) {
      return this.paymentMethods.filter(item => item.outstandingPayment);
    }
    if (type === 'simple') {
      return this.paymentMethods.filter(item => !item.creditCardTopUp && !item.bankAccountTopUp);
    }
    if (type === 'bankAccountIncomeOutcome') {
      return this.paymentMethods.filter(item => item.bankAccountTopUp);
    }
    return this.paymentMethods.filter(item => item.category && !item.creditCardTopUp && !item.bankAccountTopUp);
  }

  getCharterList(type: ExpenseSplitType): BoatShortInfoCharterListDto | null {
    return type === 'simple' ? this.charters : null;
  }

  getAllCharterList(type: ExpenseSplitType): BoatShortInfoCharterDto[] {
    if (type === 'simple') {
      return Object.values(this.charters).reduce((acc: BoatShortInfoCharterDto[], curr) => {
        if (curr) {
          acc.concat(curr);
        }
        return curr;
      }, []);
    }
    return [];
  }

  bankAccountsList(mode: ExpenseFormMode): BankAccountStatementsItem[] {
    return mode === 'create' ?
      this.bankAccounts.filter(item => item.status) :
      this.bankAccounts;
  }
}

export interface ExpenseAmountFormI {
  currencyOriginId: number;
  amountOriginal: number;
  exchangeRate: number;
  conversationAmount: number;
  creditCardExchangeRate: number;
  creditCardConversionAmount?: number;
  vatStatus?: ConditionalStatus;
  vatPercent?: number;
}

export interface ExpenseImageFormI {
  fileReceipt: UploadedFile[] | null;
  noReceipt?: boolean;
  removeReceiptFiles?: number[] | null;
}

export interface ExpenseFormI extends ExpenseImageFormI {
  userCrewId: number;
  dateReceipt: string;
  dateReportMonth?: string;
  departmentId: number;
  relatedCharter: boolean;
  departmentOneId?: number;
  charterId?: number;
  splits: PaymentFormI[];
  outstandingPaymentId: number;
}

export interface PaymentFormI extends ExpenseAmountFormI {
  paymentMethodId: number;
  description: string;
  additionalFields: AdditionalFieldFormI;
  categoryId: number;
  subCategoryId: number;
  creditCardId: number;
  bankAccountId: number;
  departmentTwoId?: number | 'guest';
  supplierNameNotRelevant?: boolean;
  supplierName?: string;
  invoiceNumberNotRelevant?: boolean;
  invoiceNumber?: string;
  id?: number;
  creditCardExchangeRate: number;
  bankAccountExchangeRate: number;
  stateTime: number;
}

export interface ExpenseSplitFormI {
  noReceipt: boolean;
  fileReceipt: UploadedFile[];
  userCrewId: number;
  dateReceipt: string;
  dateReportMonth?: string;
  departmentId: number;
  departmentOneId?: number;
  currencyOriginId: number;
  fullAmount: number;
  vatStatus: ConditionalStatus;
  vatPercent: number;
  exchangeRate: number;
  splits: PaymentFormI[];
  supplierNameNotRelevant?: boolean;
  supplierName?: string;
  additionalFields: AdditionalFieldFormI;
}

export interface AdditionalFieldFormI {
  [key: number]: string;
}

export class ExpensePageQueryParams {

  readonly charterId: number | null;
  readonly outstandingPaymentId: number | null;

  constructor(params: Params) {
    const {charterId, outstandingPaymentId} = params;
    this.charterId = isNumberValue(charterId) ? Number(charterId) : null;
    this.outstandingPaymentId = isNumberValue(outstandingPaymentId) ? Number(outstandingPaymentId) : null;
  }
}
