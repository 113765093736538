import {buildURLParams} from '@helpers/build-url-params';
import {AdditionalFieldFormI} from '@models/expense/boat-expense-form.model';
import {ExpenseFormAdditionalFieldDto} from '@models/expense/expense.model';

export function transformExpenseAdditionalFields(field: AdditionalFieldFormI, parent = ''): { [key: string]: any } {
  if (!field) {
    return {};
  }
  return Object.keys(field as object).reduce((prev, curr, i) => {
    const val = new ExpenseFormAdditionalFieldDto(+curr, field[curr] || '');
    return {...prev, ...buildURLParams(val, false, parent, i)};
  }, {});
}
