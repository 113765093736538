
export const parseParamsToArray = <T = any>(param: any, parseToNumber = false): T[] | null => {
  if (!param) {
    return null;
  }
  if (Array.isArray(param)) {
    return param.map(p => parseToNumber ? parseInt(p, 10) : p);
  }
  return parseToNumber ? [parseInt(param, 10)] : [param];
};
