export const fileExtension = (fileName: string | null): string => {
  if (!fileName) {
    return '';
  }
  const extension = fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1);
  return extension.toLowerCase();
};

export const fileExtensionFromCloudUrl = (url: string): string => {
  const extension = url.slice(0, url.lastIndexOf('?')).slice((Math.max(0, url.lastIndexOf('.')) || Infinity) + 1);
  return extension.toLowerCase();
};

export const fileNameFromCloudUrl = (url: string): string => {
  const name = url.slice(0, url.indexOf('?')).slice(url.lastIndexOf('/') + 1);
  return name.toLowerCase();
};
