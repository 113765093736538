import {transformExpenseAdditionalFields} from '@helpers/transform-expense-additional-fields';
import {Nullable} from '@helpers/utility-types';

import {
  AdditionalFieldFormI,
  AdditionalFieldsDto,
  ExpenseCrewDto,
  ExpenseDirectoriesBase,
  ExpenseDirectoriesBaseParams,
  ExpenseFormMode,
  ExpenseImageFormI,
} from './boat-expense-form.model';

import {BoatSupplierBankDetailsFormDto} from '../boat';
import {OutstandingPaymentOrderStatusDto, PaymentMethodDto} from '../directories';
import {FileListItemI} from '../file';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {OutstandingPaymentStatus} from '../outstanding-payments';
import {UploadedFile} from '../uploaded-file';

export type OutstandingPaymentFormMode = 'create' | 'edit';

type OutstandingExpenseDirectoriesParams = ExpenseDirectoriesBaseParams & {
  paymentMethods: PaymentMethodDto[];
  workOrderStatus: OutstandingPaymentOrderStatusDto[];
  paymentStatuses: OutstandingPaymentStatus[];
};

export class OutstandingExpenseDirectories extends ExpenseDirectoriesBase {

  readonly paymentMethods: PaymentMethodDto[];
  readonly workOrderStatus: OutstandingPaymentOrderStatusDto[];
  readonly paymentStatuses: OutstandingPaymentStatus[];

  constructor(params: OutstandingExpenseDirectoriesParams) {
    super(params);
    this.paymentMethods = params.paymentMethods;
    this.workOrderStatus = params.workOrderStatus;
    this.paymentStatuses = params.paymentStatuses;
  }

  paymentMethodById(id: number): PaymentMethodDto | null {
    return this.paymentMethods.find(method => method.id === id) || null;
  }

  crewById(id: number, mode: ExpenseFormMode): ExpenseCrewDto | null {
    return this.crewList(mode).find(crew => crew.crewId === id) || null;
  }

  paymentMethodsList(): PaymentMethodDto[] {
    return this.paymentMethods.filter(item => !item.creditCardTopUp);
  }

  workOrderStatusesList(): OutstandingPaymentOrderStatusDto[] {
    return this.workOrderStatus;
  }

  getPaymentStatus(statusId: number): Nullable<OutstandingPaymentStatus> {
    return this.paymentStatuses.find(({id}) => statusId === id) || null;
  }
}

export interface OutstandingPaymentForm {
  readonly id: number;
  readonly main: OutstandingExpenseForm;
  readonly bankDetails: BoatSupplierBankDetailsForm;
  readonly skipBankDetails: boolean;
}

export interface OutstandingExpenseForm extends ExpenseImageFormI {
  readonly boatCrewId: number;
  readonly departmentId: number;
  readonly invoiceQuotationDate: string;
  readonly snoozeDate: string;
  readonly workOrderStatus: number;
  readonly paymentMethodId: number | null;
  readonly paymentMethodNotRelevant: boolean;
  readonly categoryId: number;
  readonly subCategoryId: number;
  readonly additionalFields: AdditionalFieldFormI;
  readonly supplierName: string;
  readonly invoiceNumber: string | null;
  readonly invoiceNumberNotRelevant: boolean;
  readonly description: string;
  readonly notes: string;
  readonly currencyOriginId: number;
  readonly amountOriginal: number;
  readonly exchangeRate: number;
  readonly conversationAmount: number;
}

export interface BoatSupplierBankDetailsForm extends ExpenseImageFormI {
  readonly beneficiaryName: string | null;
  readonly bankName: string | null;
  readonly bankAccount: string | null;
  readonly bankAccountNotRelevant: boolean;
  readonly iban: string | null;
  readonly ibanNotRelevant: boolean;
  readonly swift: string | null;
  readonly bankAddress: string | null;
  readonly beneficiaryAddress: string | null;
  readonly boatSupplierId: number | null;
}

export class AddOutstandingPaymentFormPayload {
  readonly boatId: number;
  readonly boatCrewId: number;
  readonly departmentId: number;
  readonly invoiceQuotationDate: string;
  readonly snoozeDate: string;
  readonly workOrderStatus: number;
  readonly paymentMethodId: number | null;
  readonly paymentMethodNotRelevant: ConditionalStatus;
  readonly categoryId: number;
  readonly subCategoryId: number;
  readonly supplierName: string;
  readonly invoiceNumber: string | null;
  readonly invoiceNumberNotRelevant: ConditionalStatus;
  readonly description: string;
  readonly notes: string;
  readonly amountOriginal: number;
  readonly currencyOriginId: number;
  readonly exchangeRate: number;
  readonly noInvoice: ConditionalStatus;
  readonly skipBankDetails: ConditionalStatus | null = null;
  readonly beneficiaryName: string | null = null;
  readonly bankName: string | null = null;
  readonly bankAccount: string | null = null;
  readonly bankAccountNotRelevant: ConditionalStatus | null = null;
  readonly iban: string | null = null;
  readonly ibanNotRelevant: ConditionalStatus | null = null;
  readonly swift: string | null = null;
  readonly bankAddress: string | null = null;
  readonly beneficiaryAddress: string | null = null;
  readonly bankDetailFiles: string[] | null = null;
  readonly bankDetailOriginalFiles: string[] | null = null;
  readonly removeBankDetailFiles: number[] | null = null;

  constructor(boatId: number, form: OutstandingPaymentForm) {
    const {bankDetails, main, skipBankDetails} = form;
    const {additionalFields, fileReceipt} = main;
    this.boatId = boatId;
    this.boatCrewId = main.boatCrewId;
    this.departmentId = main.departmentId;
    this.invoiceQuotationDate = main.invoiceQuotationDate;
    this.snoozeDate = main.snoozeDate;
    this.workOrderStatus = main.workOrderStatus;
    this.paymentMethodId = main.paymentMethodId;
    this.paymentMethodNotRelevant = booleanToConditionalStatus(main.paymentMethodNotRelevant);
    this.categoryId = main.categoryId;
    this.subCategoryId = main.subCategoryId;
    this.supplierName = main.supplierName;
    this.invoiceNumber = main.invoiceNumber;
    this.invoiceNumberNotRelevant = booleanToConditionalStatus(main.invoiceNumberNotRelevant);
    this.description = main.description;
    this.amountOriginal = main.amountOriginal;
    this.currencyOriginId = main.currencyOriginId;
    this.exchangeRate = main.exchangeRate;
    this.notes = main.notes;
    if (additionalFields) {
      const fields = transformExpenseAdditionalFields(additionalFields, 'additionalFields');
      Object.keys(fields).forEach(key => this[key] = fields[key]);
    }
    this.noInvoice = booleanToConditionalStatus(main.noReceipt);
    if (fileReceipt) {
      this.setUploadedFiles('invoiceFiles', fileReceipt);
    }
    this.skipBankDetails = booleanToConditionalStatus(skipBankDetails);
    if (this.skipBankDetails === ConditionalStatus.NO) {
      this.beneficiaryName = bankDetails.beneficiaryName;
      this.bankName = bankDetails.bankName;
      this.bankAccount = bankDetails.bankAccount;
      this.bankAccountNotRelevant = booleanToConditionalStatus(bankDetails.bankAccountNotRelevant);
      this.iban = bankDetails.iban;
      this.ibanNotRelevant = booleanToConditionalStatus(bankDetails.ibanNotRelevant);
      this.swift = bankDetails.swift;
      this.bankAddress = bankDetails.bankAddress;
      this.beneficiaryAddress = bankDetails.beneficiaryAddress;
    }
    if (bankDetails.fileReceipt) {
      this.setUploadedFiles('bankDetailFiles', bankDetails.fileReceipt);
    }
    this.removeBankDetailFiles = bankDetails.removeReceiptFiles || null;
  }

  private setUploadedFiles(key: string, files: UploadedFile[]): void {
    files.forEach((file, i) => {
      if (!file.id && file.file) {
        this[`${key}[${i}][name]`] = file.fileName;
        this[`${key}[${i}][originalName]`] = file.originalFileName;
      }
    });
  }
}

export class UpdateOutstandingPaymentFormPayload extends AddOutstandingPaymentFormPayload {

  readonly outstandingPaymentId: number;
  readonly removeInvoiceFiles: number[] | null = null;

  constructor(boatId: number, form: OutstandingPaymentForm) {
    super(boatId, form);
    const {id, main} = form;
    this.outstandingPaymentId = id;
    this.removeInvoiceFiles = main.removeReceiptFiles || null;
  }

}

export class OutstandingPaymentItemPayload {
  constructor(
    public readonly boatId: number,
    public readonly outstandingPaymentId: number,
  ) {
  }
}

export interface OutstandingPaymentFormDto {
  readonly id: number;
  readonly status: number;
  readonly boatCrewId: number;
  readonly paymentMethodNotRelevant: boolean;
  readonly paymentMethodId: number | null;
  readonly departmentId: number;
  readonly currencyOriginId: number;
  readonly amountOriginal: number;
  readonly exchangeRate: number;
  readonly categoryId: number;
  readonly subCategoryId: number;
  readonly additionalFields: AdditionalFieldsDto[];
  readonly supplierName: string;
  readonly invoiceNumber: string;
  readonly invoiceNumberNotRelevant: boolean;
  readonly invoiceQuotationDate: string;
  readonly noInvoice: boolean;
  readonly invoiceFiles: FileListItemI[] | null;
  readonly supplierBankDetails: BoatSupplierBankDetailsFormDto;
  readonly description: string;
  readonly amountConverted: number;
  readonly creditCardId: number | null;
  readonly creditCardExchangeRate: number | null;
}
