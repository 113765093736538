import {buildURLParams} from '@helpers/build-url-params';
import {parseUploadFileNames, parseUploadOriginalNames} from '@helpers/parse-new-upload-file-names';
import {transformExpenseAdditionalFields} from '@helpers/transform-expense-additional-fields';

import {CharterTransactionDataDto} from './charter.model';

import {BankAccountStatementsItem, CreditCardStatementsItem} from '../credit-cards';
import {ApaOperation, ApaPaymentMethodDto, CurrencyDto, VatTypeItem} from '../directories';
import {
  AdditionalFieldFormI,
  AdditionalFieldsDto,
  ExpenseAmountFormI,
  ExpenseDirectoriesBase,
  ExpenseDirectoriesBaseParams,
  ExpenseFormMode,
  ExpenseImageFormI,
  ExpenseStateTime,
  ExpenseStatus,
  ExpenseStatusItem,
  ExpenseType,
  ExpenseTypeItem,
  FinancialDataCrewDirectory,
  SubCategoryDto,
} from '../expense';
import {ReturnUrl,booleanToConditionalStatus, ConditionalStatus} from '../general';
import {PaginationDto} from '../response';
import {GroupRange, SortDirection, SortItem} from '../shared';

export enum ApaExpenses {
  Income = 'charter.totalApaIncome',
  Expenses = 'charter.totalApaExpenses',
  Outcome = 'charter.totalApaOutcome',
  Balance = 'charter.apaExpensesBalance'
}

export enum ApaExpensesPayment {
  CashIn = 'charter.apaCashIn',
  CashIncome = 'charter.apaCashIncome',
  Atm = 'charter.apaAtm',
  Expenses = 'charter.apaCashExpenses',
  CashOut = 'charter.apaCashOut',
  CashOutcome = 'charter.apaCashOutcome',
  Balance = 'charter.apaPettyCashBalance'
}

export enum ApaTransactionType {
  APA_TYPE_EXPENSE = 1,
  APA_TYPE_INCOME,
  APA_TYPE_OUTCOME,
  // deprecated both
  APA_TYPE_CASH_MOVE_BOAT_PETTY_CASH,
  APA_TYPE_CASH_MOVE_GUEST,
}

export enum ApaViewMode {
  Yacht = 1,
  Charterer
}

export enum ApaViewRoute {
  Charterer = 'charterer',
  Yacht = 'yacht'
}

export enum ApaTransactionFormType {
  TRANSACTION = 'transaction',
  INCOME_OUTCOME = 'income-outcome',
}

export interface AddApaQueryParams {
  returnUrl: ReturnUrl | null;
}

export type CharterApaDto = Readonly<{
  apa: CharterApaTable;
  categories: CharterTransactionDataDto;
}>;

export type CharterApaPaymentDto = Readonly<{
  paymentMethodChart: CharterTransactionDataDto;
  paymentMethodList: CharterApaPaymentTable;
}>;

export type CharterApaTable = Readonly<{
  apaBalance: number;
  totalApaIncome: number;
  totalApaOutcome: number;
  totalCharterExpenses: number;
  apaAmount: number | null;
}>;

export type CharterApaPaymentTable = Readonly<{
  apaCashExpenses: number;
  apaCashIncome: number;
  apaCashOutcome: number;
  atmWithdrawal: number;
  cashIn: number;
  cashOut: number;
  charterPCB: number;
}>;

export type CharterApaTableItem = Readonly<{
  name: keyof CharterApaTable | keyof CharterApaPaymentTable;
  label: ApaExpenses | ApaExpensesPayment;
  additionalClass?: string | null;
  isOutlined?: boolean;
  combineField?: keyof CharterApaTable | keyof CharterApaPaymentTable | null;
}>;

export interface ApaBaseFilter {
  readonly charterId: number;
}

export class ApaFinancialDataFilterDto implements ApaBaseFilter {
  constructor(
    public readonly charterId: number,
    public readonly receipt: ExpenseType | null,
    public readonly checkStatus: ExpenseStatus | null,
    public readonly amountOriginalFrom: number | null,
    public readonly amountOriginalTo: number | null,
    public readonly amountConvertedFrom: number | null,
    public readonly amountConvertedTo: number | null,
    public readonly dateFrom: string | null,
    public readonly dateTo: string | null,
    public readonly description: string | null,
    public readonly page: number | null,
    public readonly limit: number | null,
    public readonly sortMode: SortDirection | null,
    public readonly sortField: string | null,
    public readonly vatIncluded: number | null,
    public readonly viewMode: ApaViewMode,
    public readonly search: string | null = null,
    subcategories: number[] | null = null,
    paymentMethods: number[] | null = null,
    transactionTypes: number[] | null = null,
    currencies: number[] | null = null,
    crewList: number[] | null = null,
  ) {
    this.mapToArrayParams(subcategories, 'subcategories');
    this.mapToArrayParams(paymentMethods, 'paymentMethods');
    this.mapToArrayParams(transactionTypes, 'transactionTypes');
    this.mapToArrayParams(currencies, 'currencies');
    this.mapToArrayParams(crewList, 'boatCrews');
  }

  private mapToArrayParams(list: (number | string)[] | null, parentKey: string) {
    if (!list) {
      return;
    }
    const params = buildURLParams(list, true, parentKey);
    Object.keys(params).forEach(key => {
      this[key] = params[key];
    });
  }
}

export interface ApaFinancialInfoFilterParams {
  page: number | null;
  limit: number;
  sortDir: SortDirection | null;
  sortBy: string | null;
  viewMode: ApaViewMode;
}

export type ApaFinancialInfoParamsType = ApaFinancialDataRawFormI & ApaFinancialInfoFilterParams;
export type ApaFinancialInfoParams = {
  [key in keyof ApaFinancialInfoParamsType]?: any | any[];
};

export type ApaFinancialDataDto = Readonly<{
  models: ApaFinancialItemDto[];
  pagination: PaginationDto;
  filter: ApaFinancialDataFilterParamsDto;
  statistic: ApaFinancialDataStatisticDto;
}>;

export type ApaFinancialItemDto = Readonly<{
  id: number;
  user: ApaFinancialUserData;
  date: string;
  department: string | null;
  paymentMethod: string;
  subcategory: string | null;
  creditCard: string | null;
  description: string;
  currency: string;
  amountOriginal: number;
  amountConverted: number;
  dateCreate: string;
  dateUpdate: string;
  checkStatus: string;
  transactionType: ApaTransactionType;
  form: ApaFinancialFormDto;
  receiptCount: number;
  vatIncluded: boolean;
  additionalFields: AdditionalFieldsDto[] | null;
  stateTime: ExpenseStateTime;
  bankAccountStateTime: ExpenseStateTime;
  charter?: string;
  bankAccount?: string;
}>;

export type ApaFinancialReceiptItemDto = Readonly<{
  id: number;
  originalName: string;
  url: string;
}>;

export type ApaFinancialFormDto = Readonly<{
  id: number;
  boatCrewId: number;
  date: string;
  department: number | null;
  creditCard: number | null;
  bankAccount: number | null;
  paymentMethod: number;
  subcategory: number | null;
  description: string;
  currency: number;
  amountOriginal: number;
  amountConverted: number;
  dateCreate: string;
  dateUpdate: string;
  checkStatus: ExpenseStatus;
  transactionType: ApaTransactionType;
  receipts: ApaFinancialReceiptItemDto[];
  exchangeRate: number;
  vatIncluded: number;
  additionalFields: AdditionalFieldsDto[] | null;
  creditCardExchangeRate: number;
  creditCardConversionAmount: number;
  bankAccountExchangeRate: number | null;
  charterId?: number;
}>;

export type ApaFinancialUserData = Readonly<{
  name: string;
}>;

export class ApaFinancialDataFilterParamsDto {
  constructor(
    public readonly price: ApaFinancialDataFilterPriceDto,
  ) {
  }
}

export class ApaFinancialDataStatisticDto {
  constructor(
    public readonly totalExpenses: number,
  ) {
  }
}

export type ApaFinancialDataFilterPriceDto = Readonly<{
  original: ApaFinancialDataPriceRangeDto;
  converted: ApaFinancialDataPriceRangeDto;
}>;

export type ApaFinancialDataPriceRangeDto = Readonly<{
  min: number | null;
  max: number | null;
}>;

export class ChangeApaStatusDto {
  constructor(
    public readonly charterApaId: number,
    public readonly status: ExpenseStatus,
  ) {
  }
}

export interface ApaFinancialDataRawFormI {
  receipt: ExpenseTypeItem | null;
  crew: FinancialDataCrewDirectory[] | null;
  subcategories: SubCategoryDto[] | null;
  transactionType: ApaOperation[] | null;
  paymentMethod: ApaPaymentMethodDto[] | null;
  currency: CurrencyDto[] | null;
  checkStatus: ExpenseStatusItem | null;
  description: string;
  amountOriginal: GroupRange<number>;
  amountConverted: GroupRange<number>;
  date: GroupRange<string>;
  show: number;
  vatIncluded: VatTypeItem | null;
}

export enum ApaFinancialBadgeTypes {
  Array,
  Obj,
  Desc
}

export type ApaFinancialBadgeItem = Readonly<{
  key: keyof ApaFinancialDataRawFormI;
  value: ApaFinancialDataRawFormI[keyof ApaFinancialDataRawFormI] | null;
  type: ApaFinancialBadgeTypes;
  toTranslate?: boolean;
}>;

export class ApaFinancialDataDirectories {
  constructor(
    public readonly subcategories: SubCategoryDto[] = [],
    public readonly crewList: FinancialDataCrewDirectory[] = [],
    public readonly transactionTypes: ApaOperation[] = [],
    public readonly paymentMethods: ApaPaymentMethodDto[] = [],
    public readonly currency: CurrencyDto[] = [],
    public readonly expenseStatus: ExpenseStatusItem[] = [],
    public readonly expenseTypes: ExpenseTypeItem[] = [],
    public readonly vatIncludedTypes: VatTypeItem[] = [],
  ) {
  }
}

export class ApaFinancialDataFilter {
  constructor(
    public filter: ApaFinancialDataRawFormI = {} as ApaFinancialDataRawFormI,
    public page: number | null = null,
    public sort: SortItem | null = null,
  ) {
  }
}

export interface AddEditCharterApaConfig {
  formType: ApaTransactionFormType;
  mode: ExpenseFormMode;
}

export interface CharterApaForm extends ExpenseImageFormI, ExpenseAmountFormI {
  id: number | null;
  boatCrewId: number;
  dateReceipt: string;
  paymentMethodId: number;
  subcategoryId: number | null;
  description: string;
  transactionType: ApaTransactionType;
  departmentId: number | null;
  creditCardId: number | null;
  bankAccountId: number | null;
  bankAccountExchangeRate: number | null;
  removeReceiptFiles: number[] | null;
  vatIncluded: boolean;
  additionalFields: AdditionalFieldFormI | null;
}

export class CharterApaPayload {
  readonly boatId: number;
  readonly transactionType: ApaTransactionType;
  readonly boatCrewId: number;
  readonly dateReceipt: string;
  readonly paymentMethodId: number;
  readonly subcategoryId: number | null;
  readonly creditCardId: number | null;
  readonly creditCardExchangeRate: number | null;
  readonly description: string;
  readonly amountOriginal: number;
  readonly currencyOriginalId: number;
  readonly exchangeRate: number;
  readonly noReceipt: ConditionalStatus;
  readonly receiptFiles: string[] | null = null;
  readonly receiptOriginalFiles: string[] | null = null;
  readonly departmentId: number | null = null;
  readonly removeReceiptFiles: number[] | null = null;
  readonly vatIncluded: number;
  readonly bankAccountId: number | null;
  readonly bankAccountExchangeRate: number | null;

  constructor(form: CharterApaForm, boatId: number) {
    this.boatId = boatId;
    this.transactionType = form.transactionType;
    this.boatCrewId = form.boatCrewId;
    this.dateReceipt = form.dateReceipt;
    this.paymentMethodId = form.paymentMethodId;
    this.subcategoryId = form.subcategoryId;
    this.description = form.description;
    this.amountOriginal = form.amountOriginal;
    this.currencyOriginalId = form.currencyOriginId;
    this.exchangeRate = form.exchangeRate;
    this.noReceipt = booleanToConditionalStatus(form.noReceipt);
    const {fileReceipt, additionalFields} = form;
    if (fileReceipt) {
      const files = fileReceipt.filter(f => f?.fileName);
      this.receiptFiles = parseUploadFileNames(files);
      this.receiptOriginalFiles = parseUploadOriginalNames(files);
    }
    this.departmentId = form.departmentId;
    this.creditCardId = form.creditCardId;
    this.bankAccountId = form.bankAccountId;
    this.bankAccountExchangeRate = form.bankAccountExchangeRate;
    this.creditCardExchangeRate = form.creditCardExchangeRate;
    this.removeReceiptFiles = form.removeReceiptFiles;
    this.vatIncluded = form.vatIncluded ? ConditionalStatus.YES : ConditionalStatus.NO;
    if (additionalFields) {
      const fields = transformExpenseAdditionalFields(additionalFields, 'additionalFields');
      Object.keys(fields).forEach(key => this[key] = fields[key]);
    }
  }

}

export class AddCharterApaPayload extends CharterApaPayload {
  constructor(
    form: CharterApaForm,
    boatId: number,
    public readonly charterId: number,
  ) {
    super(form, boatId);
  }
}

export interface AddCharterResponse {
  id: number;
}

export class UpdateCharterApaPayload extends CharterApaPayload {
  constructor(
    form: CharterApaForm,
    boatId: number,
    public readonly charterApaId: number,
  ) {
    super(form, boatId);
  }
}

type ApaExpenseDirectoriesParams = ExpenseDirectoriesBaseParams & {
  paymentMethods: ApaPaymentMethodDto[];
  transactionTypes: ApaOperation[];
  creditCards: CreditCardStatementsItem[];
  bankAccounts: BankAccountStatementsItem[];
};

export class ApaExpenseDirectories extends ExpenseDirectoriesBase {
  readonly paymentMethods: ApaPaymentMethodDto[];
  readonly transactionTypes: ApaOperation[];
  readonly creditCards: CreditCardStatementsItem[];
  readonly bankAccounts: BankAccountStatementsItem[];

  constructor(params: ApaExpenseDirectoriesParams) {
    super(params);
    this.paymentMethods = params.paymentMethods;
    this.transactionTypes = params.transactionTypes;
    this.creditCards = params.creditCards;
    this.bankAccounts = params.bankAccounts;
  }

  paymentMethodsList(type: ApaTransactionType | ApaTransactionType[], mode: ExpenseFormMode): ApaPaymentMethodDto[] {
    const methods = this.paymentMethods;
    const filtered = mode === 'create' ? methods.filter(item => !item.hiddenForAddForm) : methods;
    if (Array.isArray(type)) {
      return filtered.filter(item => type.some(t => item.apaType.includes(t)));
    }
    return filtered.filter(item => item.apaType.includes(type));
  }

  paymentMethodById(id: number): ApaPaymentMethodDto | null {
    return this.paymentMethods.find(method => method.id === id) || null;
  }

  apaOperationsList(formType: ApaTransactionFormType): ApaOperation[] {
    return this.transactionTypes.filter(t => t.formType === formType);
  }

  generalCurrency(): CurrencyDto | null {
    return this.currency.find(curr => curr.general) || null;
  }

  subcategoryList(mode: ExpenseFormMode): SubCategoryDto[] {
    if (mode === 'create') {
      return this.subCategories.filter(item => item.status);
    }
    return this.subCategories;
  }

  subCategoryById(id: number | null): SubCategoryDto | null {
    return this.subCategories.find(method => method.id === id) || null;
  }

  bankAccountsList(mode: ExpenseFormMode): BankAccountStatementsItem[] {
    return mode === 'create' ?
      this.bankAccounts.filter(item => item.status) :
      this.bankAccounts;
  }
}
