import {ExpenseSplitType, SplitDto} from './boat-expense-form.model';

import {FileListItemI} from '../file';
import {ConditionalStatus} from '../general';

export type ExpenseRelatedTypes = 'parent' | 'child' | false;

export class ExpenseFormAdditionalFieldDto {
  constructor(
    public readonly id: number,
    public readonly value: string,
  ) {
  }
}

export class ExpenseReceiptFiles implements FileListItemI {
  constructor(
    public readonly id: number,
    public readonly fileName: string,
    public readonly url: string,
    public readonly size: number,
    public readonly s3Size: number,
    public readonly s3Url: string,

  ) {
  }
}

export class ExpenseFormDto {
  [key: string]: any
  constructor(
    public readonly boatId: number,
    public readonly noReceipt: number,
    public readonly userCrewId: number,
    public readonly dateReceipt: string,
    public readonly dateReportMonth: string | null,
    public readonly departmentId: number,
    public readonly paymentMethodId: number,
    public readonly categoryId: number,
    public readonly subCategoryId: number,
    public readonly currencyOriginId: number,
    public readonly amountOriginal: number,
    public readonly exchangeRate: number,
    public readonly description: string,
    public readonly expenseId: number | null = null,
    public readonly departmentOneId: number | null = null,
    public readonly departmentTwoId: number | null = null,
    public readonly creditCardId: number | null = null,
    public readonly supplierNameNotRelevant: ConditionalStatus | null = null,
    public readonly supplierName: string | null = null,
    public readonly invoiceNumberNotRelevant: ConditionalStatus | null = null,
    public readonly invoiceNumber: string | null = null,
    public readonly creditCardExchangeRate: number | null = null,
    public readonly relatedCharter: ConditionalStatus | null = null,
    public readonly charterId: number | null = null,
    public readonly outstandingPaymentId: number | null = null,
    public readonly outstandingPaymentFileIds: number[] | null = null,
    public readonly bankAccountId: number | null = null,
    public readonly bankAccountExchangeRate: number | null = null,
  ) {
  }
}

export class PaymentFormDTO {
  constructor(
    public readonly paymentMethodId: number,
    public readonly categoryId: number,
    public readonly subCategoryId: number,
    public readonly currencyOriginId: number,
    public readonly amountOriginal: number,
    public readonly exchangeRate: number,
    public readonly description: string,
    public readonly vatStatus: ConditionalStatus,
    public readonly vatPercent: number,
    public readonly departmentTwoId: number | 'guest' | null = null,
    public readonly creditCardId: number | null = null,
    public readonly expenseId: number | null = null,
    public readonly invoiceNumberNotRelevant: ConditionalStatus | null = null,
    public readonly invoiceNumber: string | null = null,
    public readonly creditCardExchangeRate: number | null = null,
    public readonly bankAccountId: number | null = null,
    public readonly bankAccountExchangeRate: number | null = null,
  ) {

  }
}

export class SplitExpenseFormDto {
  [key: number]: string;
  constructor(
    public readonly boatId: number,
    public readonly noReceipt: number,
    public readonly userCrewId: number,
    public readonly dateReceipt: string,
    public readonly dateReportMonth: string | null,
    public readonly departmentId: number,
    public readonly currencyOriginId: number,
    public readonly fullAmount: number,
    public readonly exchangeRate: number,
    public readonly vatStatus: ConditionalStatus,
    public readonly vatPercent: number,
    public readonly expenseSplitId: number | null = null,
    public readonly departmentOneId: number | null = null,
    public readonly supplierNameNotRelevant: ConditionalStatus | null = null,
    public readonly supplierName: string | null = null,
  ) {
  }
}

export class ExpenseDto {
  constructor(
    public readonly id: number,
    public readonly boatId: number,
    public readonly noReceipt: 0 | 1,
    public readonly userCrewId: number,
    public readonly dateReceipt: string,
    public readonly description: string,
    public readonly currencyOriginId: number,
    public readonly amountOriginal: number,
    public readonly exchangeRate: number,
    public readonly paymentMethodId: number,
    public readonly departmentOneId: number,
    public departmentTwoId: number | 'guest' | null,
    public readonly creditCardId: number | null,
    public readonly optionGuest: number | null,
    public readonly categoryId: number | null,
    public readonly subCategoryId: number | null,
    public readonly receiptFiles: ExpenseReceiptFiles[],
    public readonly expenseRelatedType: ExpenseRelatedTypes,
    public readonly relatedCharter: boolean,
    public readonly splits: SplitDto[],
    public readonly supplierNameNotRelevant?: boolean,
    public readonly supplierName?: string,
    public readonly charterId?: number,
    public readonly fullAmount?: number,
  ) {
  }
}

export class OutstandingExpenseDto {
  constructor(
    public readonly outstandingPaymentId: number,
    public readonly noReceipt: 0 | 1,
    public readonly userCrewId: number,
    public readonly description: string,
    public readonly currencyOriginId: number,
    public readonly amountOriginal: number,
    public readonly exchangeRate: number,
    public readonly paymentMethodId: number | null,
    public readonly departmentOneId: number,
    public readonly categoryId: number | null,
    public readonly subCategoryId: number | null,
    public readonly receiptFiles: FileListItemI[],
    public readonly splits: SplitDto[],
  ) {
  }
}

export class ExpenseModalData {
  constructor(
    public readonly expense: ExpenseDto,
    public readonly preview: boolean,
    public readonly formType: ExpenseSplitType,
    public readonly relatedType: ExpenseRelatedTypes,
    public readonly isApaTransaction: boolean,
    public readonly isOutstandingPaymentTransaction: boolean,
  ) {
  }
}

export class ReportMonthUpdateDto {
  constructor(
    public readonly expenseId: number,
    public readonly reportMonth: string,
  ) {
  }
}

export class ChangeExpenseStatusDto {
  constructor(
    public readonly expenseId: number,
    public readonly status: ExpenseStatus,
  ) {
  }
}

export enum ExpenseStatus {
  EMPTY,
  CORRECT,
  CHECK,
  INCORRECT
}

export type ExpenseStatusItem = Readonly<{
  id: ExpenseStatus;
  name: string;
  icon: string | null;
}>;

export class LastAddedItem {
  constructor(
    public readonly id: number,
    public readonly time: number,
  ) {
  }
}

export interface AddExpenseResponseDto {
  id?: number;
  ids?: number[];
}
