import {UploadedFileMediaList} from '@models/uploaded-file';

export const DOCUMENT_TYPES = [
  'ai', 'bmp', 'gif', 'ico', 'ps', 'psd', 'svg', 'tif', 'tiff',
  'key', 'odp', 'pps', 'ppt', 'pptx',
  'ods', 'xls', 'xlsm', 'xlsx',
  'doc', 'docx', 'odt', 'pdf', 'rtf', 'tex', 'txt', 'wpd', 'pages',
];

export const ALL_IMAGES_TYPES = [
  'jpeg', 'jpg', 'png', 'bmp', 'gif', 'ico', 'svg',
];

export const MEDIA_TYPES: UploadedFileMediaList = {
  image: ['jpeg', 'jpg', 'png'],
  video: ['3gp', 'avi', 'mp4', 'mov', 'm4v'],
  document: DOCUMENT_TYPES,
};

export const ACCEPTED_UPLOAD_DOCUMENT_TYPES =
  `.ai, .bmp, .gif, .ico, .jpeg, .jpg, .png, .ps, .psd, .svg, .tif, .tiff,
  .key, .odp, .pps, .ppt, pptx,
  .ods, .xls, .xlsm, .xlsx,
  .doc, .docx, .odt, .pdf, .rtf, .tex, .txt, .wpd, .pages`;

export const ALL_UPLOADED_TYPES: string = [
  ALL_IMAGES_TYPES.map(type => `.${type}`),
  MEDIA_TYPES.video.map(type => `.${type}`),
  ACCEPTED_UPLOAD_DOCUMENT_TYPES,
].join();
