import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Moment} from 'moment';
import moment from 'moment-timezone';

import {DateType} from '@controls/date-control/models/date-control';
import {Month} from '@models/general';
import {Day, WeekDays} from '@models/general/days.model';

export enum MomentFormats {
  FULL_DATE = 'YYYY-MM-DD',
  MONTH = 'MMMM',
  MONTH_SHORT = 'MMM',
  MONTH_NUMBER = 'MM',
  STRING_DATE = 'DD MMM YYYY',
  STRING_FULL_DATE = 'MMMM D YYYY',
  YEAR = 'YYYY',
  DATE_TIME = 'YYYY-MM-DD HH:mm',
  SHORT_TIME = 'HH:mm',
  EU_FULL_DATE = 'DD-MM-YYYY',
  DATE_TIME_FIRST = 'HH:mm DD MMM YYYY',
  TEMPLATE_DATE = 'DD/MM/YYYY',
  FULL_MONTH_YEAR = 'MMMM YYYY',
  SHORT_MONTH_YEAR = 'MMM YY',
  YEAR_MONTH = 'YYYY-MM',
  DAY_SHORT = 'ddd',
  SEPARATED_STRING_DATE = 'MMMM D, YYYY'
}

export interface MomentDuration {
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}

@Injectable({
  providedIn: 'root',
})
export class MomentService {

  private get date(): Moment {
    return moment();
  }

  get currentDate(): string {
    return this.formatDate();
  }

  get currentUtcDate(): Moment {
    return moment().utc();
  }

  get currentMomentDate(): Moment {
    return this.date;
  }

  get yesterdayDate(): string {
    return this.formatDate(moment().subtract(1, 'days'));
  }

  get tomorrowDate(): string {
    return this.formatDate(moment().add(1, 'days'));
  }

  nextDaysDate(date: string, days: number): string {
    return this.formatDate(moment(date).add(days, 'days'));
  }

  prevDaysDate(date: string, days: number): string {
    return this.formatDate(moment(date).subtract(days, 'days'));
  }

  get currentMonthNumber(): number {
    return this.date.month() + 1;
  }

  get currentMonthShort(): string {
    return this.formatDate(this.date, MomentFormats.MONTH_SHORT);
  }

  get userTimeZoneOffset(): string {
    return this.date.format('Z');
  }

  get currentMonthId(): number {
    const month = this.formatDate(this.date, MomentFormats.MONTH_NUMBER);
    return parseInt(month, 10);
  }

  get currentStringDate(): string {
    return this.formatDate(this.date, MomentFormats.STRING_DATE);
  }

  get currentFullStringDate(): string {
    return this.formatDate(this.date, MomentFormats.STRING_FULL_DATE);
  }

  get currentEUFullDate(): string {
    return this.formatDate(this.date, MomentFormats.EU_FULL_DATE);
  }

  get currentYear(): number {
    const year = this.formatDate(this.date, MomentFormats.YEAR);
    return parseInt(year, 10);
  }

  get monthsShortList(): string[] {
    return moment.monthsShort();
  }

  get months(): Month[] {
    const currentMonth = moment().month();
    const previousMonth = this.previousMonth;
    const year = this.currentYear;
    return moment.months().map((month, index) => new Month(index + 1, year, month, index === currentMonth, index === previousMonth));
  }

  get years(): string[] {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const length = currentYear - startYear + 6;
    return new Array(length).fill(startYear).map((year, index) => year + index);
  }

  get pastYears(): number[] {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const length = currentYear - startYear;
    return new Array(length).fill(currentYear).map((year, index) => year - index);
  }

  get previousMonth(): number {
    return this.date.clone().subtract(1, 'month').month();
  }

  get firstDayOfMonth(): string {
    return this.formatDate(this.date.clone().startOf('month'));
  }

  getDateByYear(year: number): Date {
    const currentDate = this.currentMomentDate;
    const day = currentDate.date();
    const month = currentDate.month();
    return new Date(year, month, day);
  }

  get userTimezone(): string {
    return moment.tz.guess();
  }

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
  ) {
    moment.locale(locale, {
      week: {
        dow: 1,
      },
    });
    // @ts-ignore
    moment.suppressDeprecationWarnings = true;
  }

  formatDate(date: string | number | moment.Moment | Date = this.date, format: MomentFormats = MomentFormats.FULL_DATE): string {
    if (!this.isValid(date)) {
      return '';
    }
    return moment(date).clone().format(format);
  }

  getFirstMonthDate(month?: string | number | null, year?: string | number | null): string {
    return this.formatDate(
      new Date(
        year && +year || this.currentYear,
        month ? (+month - 1) : 0,
        1));
  }

  getLastMonthDate(month?: string | number | null, year?: string | number | null): string {
    return this.formatDate(
      new Date(
        year && +year || this.currentYear,
        month && +month || 12,
        0));
  }

  private isValid(date: string | number | moment.Moment | Date): boolean {
    return moment(date).isValid();
  }

  subtractDate(
    count: number,
    date: string | number | moment.Moment | Date | null = null,
    unit: moment.unitOfTime.DurationConstructor,
  ): moment.Moment {
    const modifiedDate = date ? moment(date).clone() : this.date.clone();
    return modifiedDate.subtract(count, unit);
  }

  addDate(
    count: number,
    date: string | number | moment.Moment | Date | null = null,
    unit: moment.unitOfTime.DurationConstructor,
  ): moment.Moment {
    const modifiedDate = date ? moment(date).clone() : this.date.clone();
    return modifiedDate.add(count, unit);
  }

  getMonthsValuesByYear(year: number) {
    return moment.months().map((m, index) => {
      const monthIndex = index + 1;
      return {
        id: monthIndex < 10 ? `${year}-0${monthIndex}` : `${year}-${monthIndex}`,
        name: m,
      };
    });
  }

  getDiffDays(first: Moment | string, second: Moment | string): number {
    const firstDate = moment(first);
    const secondDate = moment(second);
    return firstDate.diff(secondDate, 'days');
  }

  getMomentDate(value: string | Date, format?: MomentFormats): Moment {
    return moment(value, format);
  }

  getDate(value: string): Date {
    return this.getMomentDate(value).toDate();
  }

  getMonthsByPeriod(dateStart: string, dateEnd: string, format: MomentFormats): Month[] {
    const arr: Month[] = [];
    const currentMonth = moment().month();
    const previousMonth = this.previousMonth;
    const start = moment(dateStart);
    const end = moment(dateEnd);
    if (!start.isValid() || !end.isValid()) {
      return arr;
    }
    while (end > start || start.format('M') === end.format('M')) {
      const name = start.format(format);
      const index = start.month();
      const item = new Month(index + 1, start.year(), name, index === currentMonth, index === previousMonth);
      arr.push(item);
      start.add(1, 'month');
    }
    return arr;
  }

  toDate(date: string): Date {
    return moment(date).toDate();
  }

  getMonthName(monthNumber: number): string {
    return moment().set('month', monthNumber).subtract(1, 'month').startOf('month').format(MomentFormats.MONTH);
  }

  getDay(dayNumber: WeekDays, format: MomentFormats = MomentFormats.DAY_SHORT): Day {
    const dayName = moment().day(dayNumber).format(format);
    return new Day(dayNumber, dayName);
  }

  getDays(): Day[] {
    return Object.values(WeekDays).reduce((acc: Day[], item) => {
      const dayNumber = Number(item);
      if (!isNaN(dayNumber)) {
        const day = this.getDay(dayNumber);
        acc.push(day);
      }
      return acc;
    }, []);
  }

  getCurrentDateTimeByTimezone(timezoneName: string): Moment {
    const timezoneDate = moment.tz(timezoneName).format(MomentFormats.DATE_TIME);
    return moment(timezoneDate);
  }

  getDateTimeByTimezone(date: DateType, timezoneName: string): Moment {
    const timezoneDate = moment.utc(date).tz(timezoneName).format(MomentFormats.DATE_TIME);
    return moment(timezoneDate);
  }

  getUtcDateTimeByTimezone(date: DateType, timezoneName: string): Moment {
    return moment.tz(date, timezoneName).utc();
  }

  isValidDate(date: DateType): boolean {
    return moment(date, false).isValid();
  }

  getEndOfDate(date: string): string {
    return `${date} 23:59`;
  }

  getStartOfDate(date: string): string {
    return `${date} 00:00`;
  }

}
