export enum ExpensePaymentType {
  Expense = 1,
  CardTopUp,
  Apa
}

export class ExpenseAmount {
  constructor(
    public readonly value: number,
    public readonly currency: string,
  ) {
  }
}

export type ExpenseCardCurrencyPayload = {
  readonly creditCardId: number;
  readonly currencyOriginId: number;
}

export type ExpenseBankCurrencyPayload = {
  readonly bankAccountId: number | null;
  readonly currencyOriginId: number;
}

export type ExpenseCurrencyRateInfoParams = {
  readonly boatId: number;
} & (ExpenseCardCurrencyPayload | ExpenseBankCurrencyPayload);

export type ApaExpenseCurrencyRateParams = {
  readonly charterId: number;
} & (ExpenseCardCurrencyPayload | ExpenseBankCurrencyPayload);

export interface ExpenseCurrencyRateInfoDto {
  readonly transferRate: number;
  readonly amountRate: number;
}
