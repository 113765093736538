import {MEDIA_TYPES} from '@static/media-types';

import {UploadedFileType} from '@models/uploaded-file';

export const mediaTypeByExtension = (extension: string | null): UploadedFileType | null => {
  const types = MEDIA_TYPES;
  const typeKey = Object.values(UploadedFileType).find((type: UploadedFileType) => {
    const extensions = types[type];
    if (!extensions) {
      return null;
    }
    return extensions.some(ext => ext === extension);
  });
  return typeKey ? typeKey as UploadedFileType : null;
};
