
import {FileListItemI} from '@models/file';
import {FileFromServer, UploadedFile} from '@models/uploaded-file/uploaded-file';

import {fileExtensionFromCloudUrl} from './file-extension';
import {mediaTypeByExtension} from './media-type-by-extension';

export const mapUploadFilesByKey = (files: UploadedFile[], key: keyof UploadedFile): Array<string | null> => files.map(file => parseNewUploadFileByKey(file, key));

export const parseUploadFileNames = (files: UploadedFile[] | null): string[] | null => parseNewUploadFilesByKey(files, 'fileName');

export const parseUploadOriginalNames = (files: UploadedFile[] | null): string[] | null => parseNewUploadFilesByKey(files, 'originalFileName');

export const parseUploadFileName = (file: UploadedFile | null): string | null => parseNewUploadFileByKey(file, 'fileName');

export const parseUploadOriginalName = (file: UploadedFile | null): string | null => parseNewUploadFileByKey(file, 'originalFileName');

export const parseNewUploadFilesByKey = (files: (UploadedFile | string)[] | null, key: string): string[] | null => {
  if (Array.isArray(files)) {
    return files.reduce((acc: string[], curr) => {
      if (curr instanceof UploadedFile && !curr.id && curr[key]) {
        return [...acc, curr[key]];
      }
      return acc;
    }, []);
  }
  return null;
};

export const parseNewUploadFiles = (files: (UploadedFile | FileFromServer)[]): UploadedFile[] | null => {
  if (Array.isArray(files)) {
    return files.reduce((acc: UploadedFile[], curr) => {
      if (!curr.id && curr instanceof UploadedFile) {
        return [...acc, curr];
      }
      return acc;
    }, []);
  }
  return null;
};

export const parseNewUploadFileByKey = (file: UploadedFile | string | null, key: string): string | null => {
  if (!file) {
    return null;
  }
  return file instanceof UploadedFile && !file.id && file[key] ? file[key] : null;
};

export const parseUploadedFiles = (files: UploadedFile[] | null, prefix: string): {[key: string]: string} => {
  if (!files?.length) {
    return {};
  }
  return files.reduce((acc: {[key: string]: string}, curr) => {
    if (!curr.id) {
      const filesLength = Object.values(acc).length / 2;
      acc[`${prefix}[${filesLength}][fileName]`] = curr.fileName as string;
      acc[`${prefix}[${filesLength}][originalFileName]`] = curr.originalFileName as string;
    }
    return acc;
  }, {});
};

export const parseS3FilesToUploaded = (files: FileListItemI[] | null): UploadedFile[] => {
  if (!files) {
    return [];
  }
  return files.reduce((acc: UploadedFile[], curr) => {
    const {id, originalFileName, fileName, s3Url} = curr;
    const ext = fileExtensionFromCloudUrl(s3Url);
    const type = mediaTypeByExtension(ext);
    if (!type) {
      return acc;
    }
    const file = new UploadedFile(id, null, fileName, null, s3Url, ext, type, originalFileName);
    acc.push(file);
    return acc;
  }, []);
};
